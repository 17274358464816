import * as React from 'react'
import t from 'typy'
import { inject, observer } from 'mobx-react'

import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import RichText from 'src/components/Modules/RichText'
import Subject from 'src/components/Faq/Subject'
import styles from 'src/components/Faq/Faq.module.scss'
import { getSeoData } from 'src/helpers/getSeoData'
import SEO from 'src/components/SEO/SEO'

type Props = {
  pageContext: any
  preview?: any
}

@inject('preview')
@observer
class FAQ extends React.Component<Props> {
  render() {
    const { pageContext, preview } = this.props
    let { page } = pageContext

    if (preview.previewData) {
      const previewData = preview.getCurrentPageContext(
        page.contentType,
        page.locale,
        page.slug
      )
      if (previewData) {
        page = previewData
      }
    }

    const { seoData } = page
    let subjects = t(page, 'subjects').safeObject
    if (subjects) {
      subjects = subjects.map((subject: any) => {
        return {
          ...t(subject, 'fields').safeObject,
        }
      })
    }
    return (
      <div className={styles.faqWrapper}>
        <SEO {...getSeoData(seoData)} />
        <RichText content={page.content} />
        <div className={styles.linkList}>
          {subjects &&
            subjects.map((subject: any, index: number) => (
              <LinkComponent
                className={styles.link}
                key={index}
                to={`${page.slug}#subject${index}`}
                locale={page.locale}
              >
                {subject.title}
              </LinkComponent>
            ))}
        </div>
        <div>
          {subjects &&
            subjects.map((subject: any, index: number) => (
              <Subject key={index} id={`subject${index}`} subject={subject} />
            ))}
        </div>
      </div>
    )
  }
}

export default FAQ
