import * as React from 'react'

import Tab from './Tab'

type TabListProps = {
  items: object[]
}

const TabList = ({ items }: TabListProps): JSX.Element => (
  <React.Fragment>
    {items.map(
      (item: any, index: number): JSX.Element => (
        <Tab key={index} first={index === 0} {...item} />
      )
    )}
  </React.Fragment>
)

export default TabList
