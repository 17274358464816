import * as React from 'react'
import t from 'typy'

import TabList from 'src/components/Product/TabList/TabList'

import styles from './Faq.module.scss'

type Props = {
  subject: any
  id: string
}

const Subject = ({ subject, id }: Props) => {
  let questions = t(subject, 'questions').safeObject
  if (questions) {
    questions = questions.map((question: any) => {
      return {
        heading: t(question, 'fields.title').safeObject,
        richText: t(question, 'fields.answer').safeObject,
      }
    })
  }

  return (
    <div id={id} className={styles.subject}>
      <h4>{subject.title}</h4>
      <TabList items={questions} />
    </div>
  )
}

export default Subject
