import * as React from 'react'
import { autobind } from 'core-decorators'
import isNode from 'is-node'
import posed from 'react-pose'

import { ChevronDown } from 'src/components/UI/Icon/Icon'
import RichText from 'src/components/Modules/RichText'

import styles from './TabList.module.scss'

type Props = {
  heading: string
  text?: string[]
  richText?: any
}

type State = {
  open: boolean
}

const Content = posed.div({
  closed: { height: 0, overflow: 'hidden' },
  open: { height: 'auto', overflow: 'hidden' },
})

export default class Tab extends React.PureComponent<Props, State> {
  state = {
    open: false,
  }

  constructor(props: Props) {
    super(props)
    this.state.open = props.isOpen
  }

  @autobind
  toggleTab(): void {
    this.setState((prevState: State): any => ({
      open: !prevState.open,
    }))
  }

  render(): JSX.Element {
    const { heading, text, richText } = this.props
    const { open } = this.state

    return (
      <div className={styles.wrapper}>
        <button onClick={this.toggleTab}>
          {heading && <h3>{heading}</h3>}
          <ChevronDown
            color="black"
            styles={open ? styles.openChevron : null}
            width={13}
          />
        </button>
        {(!isNode || open) && (
          <Content pose={open ? 'open' : 'closed'}>
            {text && !richText && <p dangerouslySetInnerHTML={{ __html: text }} />}
            {richText && (
              <div>
                <RichText content={richText} />
              </div>
            )}
          </Content>
        )}
      </div>
    )
  }
}
